@import "styles/_variables.scss";

.button {
    padding: 11px 16px;
    color: $greenBackground;
    background-color: #FCFBF8;
    border: 1px solid $greenBorderColor;
    border-radius: 4px;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    outline: none;
    transition: all .3s linear;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
        cursor: pointer;
        background-color: $greenBackground;
        color: $white;
    }
}