@import "styles/_variables.scss";

.section {
    position: relative;
    width: 100%;
    padding: 80px 0;

    @media(max-width: $tabletPoint) {
        padding: 56px 0;
    }

    &_light {
        background: #FCFBF8;
    }

    &_gray {
        background: #F5F3F0;
    }
}

.container {
    margin: 0 auto;

    &_large {
        max-width: $middleDesktopContainer;
        position: relative;

        @media(max-width: $tabletPoint) {
            max-width: $middleTabletContainer;
        }
        @media(max-width: $mobilePoint) {
            max-width: 100%;
            padding: 0 16px;
        }
    }

    &_small {
        max-width: $smallDesktopContainer;

        @media(max-width: $mobilePoint) {
            max-width: 100%;
            padding: 0 16px;
        }
    }
}

.title {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
    color: $mainText;

}

.subtitle {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: $mainText;
}