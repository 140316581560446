@import "styles/_variables.scss";

.container {
    display: flex;
    border: 1px solid #DFDEDF;
    border-radius: 4px;
    width: 100%;
    padding: 14.5px 16px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    &:last-child{
        margin-bottom: 32px;
    }
    &__item {
        display: flex;
        align-items: center;
        &__name {
            font-family: $fontFamily;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: $mainText;
            width: 79%;
        }

        &__size {
            font-family: $fontFamily;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: $secondaryText;
        }

        &__delete {
            width: 24px;
            height: 24px;
            transition: opacity .2 linear;
            margin-left: 20px;
            &:hover{
                cursor: pointer;
                opacity: .8;
            }
        }
    }
}