@import "styles/_variables.scss";

.title {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
    color: $blogMainText;
    margin-bottom: 32px;
}