@import "styles/_variables.scss";

.section {
    width: 100%;
    padding: 80px 0;
    background-color: $greenBackground;
}

.block {
    margin: 0 auto;
    width: 792px;

    @media(max-width: $tabletPoint) {
        width: 724px;
    }

    @media(max-width: $mobilePoint) {
        width: 100%;
        padding: 0 16px;
    }
}

.title {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
    text-align: center;
    color: $white;
    margin-bottom: 32px;
}

.button {
    display: flex;
}